import drop_1 from "./whitelists/drop_1_whitelist.json";
import drop_2 from "./whitelists/drop_2_whitelist.json";
import drop_3 from "./whitelists/drop_3_whitelist.json";
import drop_4 from "./whitelists/drop_4_whitelist.json";
import drop_5 from "./whitelists/drop_5_whitelist.json";
import drop_6 from "./whitelists/drop_6_whitelist.json";
import drop_7 from "./whitelists/drop_7_whitelist.json";
import drop_8 from "./whitelists/drop_8_whitelist.json";
import drop_9 from "./whitelists/drop_9_whitelist.json";
import drop_10 from "./whitelists/drop_10_whitelist.json";
import drop_11 from "./whitelists/drop_11_whitelist.json";
import drop_12 from "./whitelists/drop_12_whitelist.json";
import drop_13 from "./whitelists/drop_13_whitelist.json";
import drop_14 from "./whitelists/drop_14_whitelist.json";
import drop_15 from "./whitelists/drop_15_whitelist.json";
import drop_16 from "./whitelists/drop_16_whitelist.json";
import drop_17 from "./whitelists/drop_17_whitelist.json";
import drop_18 from "./whitelists/drop_18_whitelist.json";
import drop_19 from "./whitelists/drop_19_whitelist.json";
import drop_20 from "./whitelists/drop_20_whitelist.json";
import drop_21 from "./whitelists/drop_21_whitelist.json";
import drop_22 from "./whitelists/drop_22_whitelist.json";
import drop_23 from "./whitelists/drop_23_whitelist.json";
import drop_24 from "./whitelists/drop_24_whitelist.json";
import drop_25 from "./whitelists/drop_25_whitelist.json";
import drop_26 from "./whitelists/drop_26_whitelist.json";
import drop_27 from "./whitelists/drop_27_whitelist.json";
import drop_28 from "./whitelists/drop_28_whitelist.json";
import drop_29 from "./whitelists/drop_29_whitelist.json";
import drop_30 from "./whitelists/drop_30_whitelist.json";
import drop_31 from "./whitelists/drop_31_whitelist.json";
import drop_32 from "./whitelists/drop_32_whitelist.json";
import drop_33 from "./whitelists/drop_33_whitelist.json";
import drop_34 from "./whitelists/drop_34_whitelist.json";

const drops = {
    drop1: drop_1.holders,
    drop2: drop_2.holders,
    drop3: drop_3.holders,
    drop4: drop_4.holders,
    drop5: drop_5.holders,
    drop6: drop_6.holders,
    drop7: drop_7.holders,
    drop8: drop_8.holders,
    drop9: drop_9.holders,
    drop10: drop_10.holders,
    drop11: drop_11.holders,
    drop12: drop_12.holders,
    drop13: drop_13.holders,
    drop14: drop_14.holders,
    drop15: drop_15.holders,
    drop16: drop_16.holders,
    drop17: drop_17.holders,
    drop18: drop_18.holders,
    drop19: drop_19.holders,
    drop20: drop_20.holders,
    drop21: drop_21.holders,
    drop22: drop_22.holders,
    drop23: drop_23.holders,
    drop24: drop_24.holders,
    drop25: drop_25.holders,
    drop26: drop_26.holders,
    drop27: drop_27.holders,
    drop28: drop_28.holders,
    drop29: drop_29.holders,
    drop30: drop_30.holders,
    drop31: drop_31.holders,
    drop32: drop_32.holders,
    drop33: drop_33.holders,
    drop34: drop_34.holders,
}

export default drops;