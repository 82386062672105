import { ethers } from 'ethers';
import keccak256 from "keccak256";
import { MerkleTree } from "merkletreejs";

export interface WhitelistItem {
    tokenId: number;
    maxAmount: number;
    recipient: string;
}

const timmOriginalsLib = {
    /**
     * Hash a whitelist item to be used as a leaf on the merkle tree.
     * @param item 
     * @returns 
     */
    hashItem(item: WhitelistItem): Buffer {
        return Buffer.from(ethers.utils.solidityKeccak256(['uint256', 'address', 'uint256'], [item.tokenId, item.recipient.toLowerCase(), item.maxAmount]).slice(2), 'hex');
    },

    /**
     * Builds a merkle tree from a list of whitelist items.
     * @param whitelist The items to build the tree from.
     * @returns 
     */
    getMerkleTree(whitelist: WhitelistItem[]) {
        if (whitelist.length === 0) throw new Error("Invalid empty whitelist");
        if (whitelist.length % 2 !== 0) throw new Error("whitelist entries must be even");

        return new MerkleTree(whitelist.map(x => this.hashItem(x)), keccak256, { sortPairs: true });
    },

    /**
     * Builds the prof from a merkle tree and a whitelist item.
     * @param merkleTree 
     * @param item 
     * @returns 
     */
    getProof(merkleTree: MerkleTree, item: WhitelistItem) {
        const node = this.hashItem(item);
        const proof = merkleTree.getHexProof(node);
        return proof;
    },
}

export default timmOriginalsLib;