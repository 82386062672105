import { Contract } from "@ethersproject/contracts";
import {
  shortenAddress,
  useEthers,
  useLookupAddress,
  useContractFunction,
} from "@usedapp/core";
import React, { useEffect, useState } from "react";

import {
  Alert,
  Body,
  Button,
  Container,
  Header,
  Image,
  Select,
} from "./components";
import logo from "./wagmamLogo.png";

import { addresses, abis } from "@my-app/contracts";

import timmOriginalsLib from "./timmOriginalsLib";
import whitelists from "./whitelists";

function WalletButton() {
  const [rendered, setRendered] = useState("");

  const ens = useLookupAddress();
  const { account, activateBrowserWallet, deactivate, error } = useEthers();

  useEffect(() => {
    if (ens) {
      setRendered(ens);
    } else if (account) {
      setRendered(shortenAddress(account));
    } else {
      setRendered("");
    }
  }, [account, ens, setRendered]);

  useEffect(() => {
    if (error) {
      console.error("Error while connecting wallet:", error.message);
    }
  }, [error]);

  return (
    <Button
      onClick={() => {
        if (!account) {
          activateBrowserWallet();
        } else {
          deactivate();
        }
      }}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </Button>
  );
}

function App() {
  const drops = {
    drop1: { name: "Drop #1", id: 1 },
    drop2: { name: "Drop #2", id: 2 },
    drop3: { name: "Drop #3", id: 3 },
    drop4: { name: "Drop #4", id: 4 },
    drop5: { name: "Drop #5", id: 5 },
    drop6: { name: "Drop #6", id: 6 },
    drop7: { name: "Drop #7", id: 7 },
    drop8: { name: "Drop #8", id: 8 },
    drop9: { name: "Drop #9", id: 9 },
    drop10: { name: "Drop #10", id: 10 },
    drop11: { name: "Drop #11", id: 11 },
    drop12: { name: "Drop #12", id: 12 },
    drop13: { name: "Drop #13", id: 13 },
    drop14: { name: "Drop #14", id: 14 },
    drop15: { name: "Drop #15", id: 15 },
    drop16: { name: "Drop #16", id: 16 },
    drop17: { name: "Drop #17", id: 17 },
    drop18: { name: "Drop #18", id: 18 },
    drop19: { name: "Drop #19", id: 19 },
    drop20: { name: "Drop #20", id: 20 },
    drop21: { name: "Drop #21", id: 21 },
    drop22: { name: "Drop #22", id: 22 },
    drop23: { name: "Drop #23", id: 23 },
    drop24: { name: "Drop #24", id: 24 },
    drop25: { name: "Drop #25", id: 25 },
    drop26: { name: "Drop #26", id: 26 },
    drop27: { name: "Drop #27", id: 27 },
    drop28: { name: "Drop #28", id: 28 },
    drop29: { name: "Drop #29", id: 29 },
    drop30: { name: "Drop #30", id: 30 },
    drop31: { name: "Drop #31", id: 31 },
    drop32: { name: "Drop #32", id: 32 },
    drop33: { name: "Drop #33", id: 33 },
    drop34: { name: "Drop #34", id: 34 },
  };
  const [localError, setLocalError] = useState("");
  const { account } = useEthers();
  const [selectedDrop, setSelectedDrop] = useState("drop34");

  const { state: mintState, send: sendMint } = useContractFunction(
    new Contract(addresses.wagmam, abis.wagmam),
    "mint",
    { transactionName: "Mint" }
  );

  const handleClaimClicked = async () => {
    if (!account) return;
    const dropList = whitelists[selectedDrop as keyof typeof whitelists];
    if (!dropList) {
      console.error("No droplist for drop", selectedDrop);
      return;
    }
    const tree = timmOriginalsLib.getMerkleTree(
      Object.keys(dropList).map((key: string) => {
        return {
          tokenId: dropList[key as keyof typeof dropList].tokenId,
          maxAmount: dropList[key as keyof typeof dropList].maxAmount,
          recipient: key.toLowerCase(),
        };
      })
    );

    const item = dropList[account.toLowerCase() as keyof typeof dropList];
    console.log("GETTING FOR ITEM FROM KEY", account);
    if (!item) {
      setLocalError("User is not on the whitelist");
      return;
    } else {
      setLocalError("");
    }

    const proof = timmOriginalsLib.getProof(tree, {
      recipient: account.toLowerCase(),
      tokenId: item.tokenId,
      maxAmount: item.maxAmount,
    });

    const dropId = drops[selectedDrop as keyof typeof drops].id;
    await sendMint(
      dropId,
      item.tokenId,
      account.toLowerCase(),
      item.maxAmount,
      proof
    );
  };

  const onSelectedDropChange = (e: any) => {
    setSelectedDrop(e.target.value);
  };

  console.log("STATE", mintState);

  return (
    <Container>
      <Header>
        <WalletButton />
      </Header>
      <Body>
        <Image src={logo} alt="wagmam-logo" />
        <p>The WAGMAM Collection</p>
        <p
          style={{
            maxWidth: "1024px",
            fontSize: "16px",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          The Infinite Machine Collection team wants to reward its holders with
          an airdrop of works created by our 34 artists.
          <br />
          As WAGMAM, we have decided that this new derivative collection will
          have “cinema”, or “the cinematic”, as a common theme. Cinema is the
          artistic manifestation that in itself brings together visual arts,
          literature, and technology. In turn, films can be used as a powerful
          tool for communication, dissemination, and freedom. <br />
          It is precisely on this basis of diverse expressions and functions
          that the community of Ethereans has been created. Therefore, the
          artists have created pieces that reference cinema or cinematography.
          These references are achieved either from the use of iconographic
          motifs and symbols of cinema, or with the use of film language
          resources in the composition of the image.
        </p>
        <p>
          <Select
            disabled={!account || mintState.status === "Mining"}
            name="drop"
            id="drop"
            onChange={onSelectedDropChange}
            value={selectedDrop}
          >
            {Object.keys(drops).map((dropKey: string) => (
              <option value={dropKey} key={dropKey}>
                {drops[dropKey as keyof typeof drops].name}
              </option>
            ))}
          </Select>
          <Button
            disabled={!account || mintState.status === "Mining"}
            onClick={handleClaimClicked}
          >
            {mintState.status === "Mining"
              ? "Minting..."
              : mintState.status === "Success"
              ? "Minted!"
              : "Claim"}
          </Button>
        </p>
        {(mintState.errorMessage || localError) && (
          <Alert>{mintState.errorMessage || localError}</Alert>
        )}
      </Body>
    </Container>
  );
}

export default App;
